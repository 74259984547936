var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { key: _vm.key, attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs4: "" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$store.state.identity.configuration.avatarUrl) +
                "\n    "
            )
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs4: "" } },
            [
              _c("v-text-field", {
                attrs: { label: "Avatar URL", "append-icon": "mdi-check" },
                on: { "click:append": _vm.apply },
                model: {
                  value: _vm.avatarUrl,
                  callback: function($$v) {
                    _vm.avatarUrl = $$v
                  },
                  expression: "avatarUrl"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs6: "", lg1: "" } },
            [
              _c("starling-avatar-input", {
                on: { saved: _vm.onSaved },
                model: {
                  value: _vm.image,
                  callback: function($$v) {
                    _vm.image = $$v
                  },
                  expression: "image"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "pt-5" },
        [
          _c(
            "v-flex",
            { staticClass: "text-center" },
            [
              _c("primary-button", { on: { click: _vm.onReset } }, [
                _vm._v("Reset")
              ]),
              _c("primary-button", { on: { click: _vm.onRandomize } }, [
                _vm._v("Randomize")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }