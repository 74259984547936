<template>
  <v-container fluid :key="key">
    <v-layout row wrap>
      <v-flex xs4>
        {{ $store.state.identity.configuration.avatarUrl }}
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs4>
        <v-text-field label="Avatar URL"
                      v-model="avatarUrl"
                      append-icon="mdi-check"
                      @click:append="apply"/>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs6 lg1>
        <starling-avatar-input v-model="image" @saved="onSaved"/>
      </v-flex>

    </v-layout>
    <v-layout class="pt-5">
      <v-flex class="text-center">
        <primary-button @click="onReset">Reset</primary-button>
        <primary-button @click="onRandomize">Randomize</primary-button>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import StarlingAvatarInput from '@/views/avatar/starling-avatar-input.vue';
import { getDefault, getRandomAvatarOption } from '@/views/avatar/utils';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default {
  name: 'Testing',
  components: {
    PrimaryButton,
    StarlingAvatarInput,
  },
  data() {
    return {
      key: 0,
      image: getRandomAvatarOption({}),
      avatarUrl: this.$store.state.identity.configuration.avatarUrl,
    };
  },
  beforeCreate() {
    this.$store.commit('identity/configureBackend', {
      organization: null,
      configuration: {
        avatarUrl: 'https://avatar.dev.starlingminds.com',
      },
      subdomain: null,
    });
  },
  methods: {
    apply() {
      this.$store.commit('identity/configureBackend', {
        organization: null,
        configuration: {
          avatarUrl: this.avatarUrl,
        },
        subdomain: null,
      });
      this.key++;
    },
    onReset() {
      this.image = getDefault();
    },
    onRandomize() {
      this.image = getRandomAvatarOption({});
    },
    onSaved() {

    },
  },
};
</script>

<style scoped>
</style>
